<template>
  <v-snackbar v-model="snack" multi-line>
    {{ text }}
    <template v-slot:action="{ attrs }">
      <v-btn color="blue" text v-bind="attrs" @click="$emit('close')">
        Close
      </v-btn>
    </template>
  </v-snackbar>
</template>

<script>
/**
 * Reusable Component for Toast
 * 
 * <snackbar 
    :snack="snack"
    :text="text"
    timeout="2000"
    @close="snack=false"/>
 */
export default {
  props: ["snack", "text", "timeout"],
  watch: {
    snack(val) {
      if (val) {
        setTimeout(() => {
          this.$emit("close");
        }, this.timeout);
      }
    },
  },
};
</script>

